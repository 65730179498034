import { Loader } from '@/app/loading'
import SearchCard from '@/core/compositions/searchCard'
import LocationCard from '@/core/compositions/locationCard'
import Pagination from '@/core/elements/pagination'

// Content
const MainContent = ({
    ctaPosition,
    cta,
    data,
    CTA,
    handlePageChange,
    page = null,
    isLocation = false,
    isLoading = false,
  }) => {
    const Card = isLocation ? LocationCard : SearchCard

    if (isLoading) {
      // Show loader when loading
      return <Loader />
    }

    return (
        <>
            {data &&
                data?.items?.length > 0 &&
                data.items?.map((item, index) => {
                    if (index === ctaPosition) {
                        return (
                            <div key={`search-result-index-${index}`}>
                                <div className="resultsCardHolder">
                                    <CTA {...cta} />
                                </div>
                                <div className="resultsCardHolder">
                                    <Card {...item} />
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div
                            className="resultsCardHolder"
                            key={`search-result-index-${index}`}
                        >
                            <Card {...item} />
                        </div>
                    )
            })}
            {data?.items?.length < ctaPosition && (
                <div key={`search-result-index-${ctaPosition}`}>
                    <div className="resultsCardHolder">
                        <CTA {...cta} />
                    </div>
                </div>
            )}
            {data?.count > 0 && (
                <div className="pagination">
                    <Pagination
                        count={data.pages}
                        handleChange={handlePageChange}
                        page={page}
                    />
                </div>
            )}
            {isLoading && <Loader />}
            {
                !data ||
                (!data?.items?.length && 
                    (
                        <div className="noContentItem">Geen items gevonden met deze criteria</div>
                    )
                )
            }
        </>
    )
}

export default MainContent
