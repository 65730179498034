import React from 'react'
import styled from 'styled-components'
import { list_bg_grey, list_border_grey } from '../../colors'
import List from '../../compositions/list'

const ListsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px 51px;
  border: 1px solid ${list_border_grey};
  background-color: ${list_bg_grey};
  .column {
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 1000px) {
    display: block;

    .column {
      display: block;
      margin-bottom: 20px;
    }
  }
`

const ListsBlock = ({ lists }) => {
  return (
    <ListsContainer>
      {lists?.map((list) => {
        return (
          <div className="column" key={list.title}>
            <List {...list} />
          </div>
        )
      })}
    </ListsContainer>
  )
}

export default ListsBlock
