'use client'
import React from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import TypographyElement from '../elements/typography'
import { lightgrey } from '../colors'
import { innerHtml } from '../utils/innerHtml'

const FaqWrapper = styled.button`
  display: block;
  background-color: ${lightgrey};
  padding: 15px 32px;
  width: 100%;
  border: 0;
  text-align: left;
  svg,
  .title {
    display: inline-block !important;
    vertical-align: middle;
  }
  svg {
    width: 24px;
  }
  .title {
    width: calc(100% - 24px);
  }
  .answerContainer {
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 12px;

    .innerhtml {
      * {
        font-family: 'Dosis' !important;
        font-size: 16px !important;
        line-height: 1.25 !important;
      }
    }
  }
`

const FAQCard = ({ title, answer }) => {
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }
  return (
    <FaqWrapper onClick={handleToggle}>
      <TypographyElement variant="faqTitle" className="title">
        {title}
      </TypographyElement>
      {open ? <RemoveIcon /> : <AddIcon />}
      {open && <div className="answerContainer">{innerHtml(answer, true)}</div>}
    </FaqWrapper>
  )
}

export default FAQCard
