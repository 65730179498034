import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTheme } from '@mui/material'

import ImageBuilder from '../../elements/imageBuilder'
import TypographyElement from '../../elements/typography'
import SvgElement from '../../elements/svg'
import { black, white } from '../../colors'
import { getMomentTz } from '../../utils/getMomentTz'

const Card = styled.div`
  min-height: 410px;
  max-height: 460px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: ${(props) => (props.isLarge ? '66%' : '32%')};
  ${(props) => (props.maxWidth ? 'width: 100%;' : '')}
  background: #fff;
  margin-bottom: 30px;

  @media screen and (max-width: 1200px) {
    width: 48%;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
  }

  .linkoverlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  a {
    text-decoration: none;

    :hover,
    :focus-visible,
    :focus {
      .overlay {
        transform: translateY(-340px)
          rotate(${(props) => (props.isLarge ? '-2.86deg' : '-4.87deg')});
      }
    }
  }
  .image {
    height: 304px;
    width: 100%;
    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  div.whitebar {
    position: absolute;
    width: 120%;
    height: 400px;
    background: #fff;
    left: -2%;
    top: ${(props) => (props.isLarge ? '265px' : '265px')};
    transform: rotate(${(props) => (props.isLarge ? '-2.86deg' : '-4.87deg')});
  }

  .content {
    position: relative;
    top: -20px;
    padding: 0 31px 21px;
    height: 130px;
    .extra {
      text-align: right;
      svg {
        vertical-align: middle;
        display: inline-block;
        margin: 0 9px;
      }
    }
    .title {
      margin-top: 12px;
    }
    > svg {
      position: absolute;
      right: 31px;
      bottom: 21px;
      width: 12px;
      transform: scale(1.5);
      path {
        stroke: 'none';
        fill: ${black};
      }
    }
  }

  .overlay {
    position: relative;
    display: block;
    width: 150%;
    height: 400px;
    left: -25%;
    background: ${(props) => props.hoverColor};
    opacity: 0.9;
    background-blend-mode: darken;
    transform: translateY(100%)
      rotate(${(props) => (props.isLarge ? '-2.86deg' : '-4.87deg')});
    
    transition: 0.5s ease-in-out;
    .text {
      position: absolute;
      top: 30%;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      transform: rotate(${(props) => (props.isLarge ? '2.86deg' : '4.87deg')});
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        path {
          stroke: none;
          fill: ${white};
        }
      }
    }
  }

  // Mobile
  @media screen and (max-width: 400px) {
    .content {
      padding: 0 0px 21px;
      svg {
        display: none;
      }
    }
  }
`

const NewsCard = ({
  title,
  category,
  date,
  href,
  url,
  image,
  img,
  search_img,
  isLarge = false,
  showCategory = true,
  maxWidth,
  dateColor = null,
  decorativeOnly = false,
}) => {
  const theme = useTheme()
  const primaryColor =
    theme.globalTheme?.palette?.primaryCustom?.main || 'rgba(46, 132, 42, 1)'

  const useImage = search_img || img || image
  const link = url || href

  return (
    <Card isLarge={isLarge} maxWidth={maxWidth} hoverColor={primaryColor}>
      <Link href={link} className="linkoverlay" title={title}>
        <div className="image">
          {useImage && (
            <ImageBuilder
              image={useImage}
              layout="fill"
              decorativeOnly={decorativeOnly}
            />
          )}
        </div>
        <div className="whitebar"></div>
        <div className="content">
          <div className="extra">
            {category && (
              <TypographyElement variant="newsCardCategory">
                {category}
              </TypographyElement>
            )}
            {showCategory && (
              <>
                {category && <SvgElement variant="textdivider" />}
                {date && (
                  <TypographyElement variant="newsCardDate" color={dateColor}>
                    {getMomentTz(date).format('DD-MM-YYYY')}
                  </TypographyElement>
                )}
              </>
            )}
          </div>
          <div className="title">
            <TypographyElement variant={'newsCardTitleElipsis'} component="p">
              {title}
            </TypographyElement>
          </div>
          <ArrowForwardIcon />
        </div>
        <div className="overlay">
          <div className="text">
            <TypographyElement variant="readMoreWhite">
              Lees meer
            </TypographyElement>
            <ArrowForwardIcon />
          </div>
        </div>
      </Link>
    </Card>
  )
}

export default NewsCard
