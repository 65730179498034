import styled from 'styled-components'
import { white } from '../../../colors'

export const ToggleButton = styled.button`
  display: inline-block;
  vertical-align: top;
  padding: 15px 21px;
  margin: 0 0 0 15px;

  background: #ffffff;
  border: 1px solid #d7d7d7;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &.active {
    background: rgba(59, 170, 52, 0.26);
    border: 1px solid rgba(59, 170, 52, 0.26);
  }
`

export const Wrapper = styled.div`
  .options {
    padding: 40px 0 0;

    @media screen and (max-width: 1000px) {
      padding-top: 0;
    }
  }

  .filterContentSidebar {
    width: 450px;

    @media screen and (max-width: 1280px) {
      width: 350px;
    }

    @media screen and (max-width: 1180px) {
      @keyframes animatein {
        0% {
          left: -140%;
        }
        100% {
          left: 0;
        }
      }

      width: 100%;
      position: fixed;
      top: 0;
      left: -100%;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      z-index: 999999;

      > .bg {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
      }

      &.open {
        animation-name: animatein;
        animation-duration: 0.5s;
        left: 0;
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .bg {
    background: #e4eaef;
    padding: 40px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 450px) {
      padding: 22px;
    }
  }

  .maincontent {
    width: calc(100% - 450px);
    padding: 0 0 0 40px;

    @media screen and (max-width: 1280px) {
      width: calc(100% - 350px);
    }

    @media screen and (max-width: 1180px) {
      width: calc(100%);
      padding: 0;
    }
  }

  .resultsCardHolder {
    padding: 20px 0;

    h3 {
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 1180px) {
      padding: 20px 30px;
    }

    @media screen and (max-width: 800px) {
      padding: 20px 0px;
    }

    > a {
      display: block;
    }
  }

  .pagination {
    padding: 40px 0;
  }

  > div {
    display: inline-block;
    vertical-align: top;
  }

  .contentBlock {
    background: #fff;
  }

  .filterNum {
    display: inline-block;
    vertical-align: middle;
    width: 130px;

    span {
      display: inline-block;
      color: #fff;
      background: #1f486a;
      padding: 12px 18px;
      font-weight: 700;
      margin-right: 12px;
    }

    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;

    h2 {
      display: inline-block;
      margin: 0;
      font-family: 'Dosis';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .selectedFilters {
    display: block;
    margin-top: 25px;
  }

  .editfilter {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 130px);
    padding: 12px 0;
    text-align: right;

    @media screen and (max-width: 650px) {
      width: 100%;
      text-align: left;
      padding: 12px 0;
      margin-top: 12px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .content {
    padding: 40px 0;
  }

  .sidebarBlock {
    padding-top: 40px;
  }

  .sorting,
  .summary {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: 750px) {
      width: 100%;
      padding: 12px 0;
    }
  }

  .sorting {
    @media screen and (max-width: 1000px) {
      > div {
        width: 100%;
      }
    }
  }

  .summary {
    text-align: right;
  }

  p {
    display: inline-block;
  }

  .pline {
    line-height: 53px;

    @media screen and (max-width: 740px) {
      margin-top: -60px;
    }
    @media screen and (max-width: 340px) {
      margin-top: 0;
    }
  }

  .listMapWrapper {
    padding-bottom: 60px;
  }

  .mobileFilters {
    display: none;

    @media screen and (max-width: 1180px) {
      display: inline-block;
      padding: 0 0 20px 0;
      text-align: right;

      color: #666666;
      font-family: 'Dosis';
      font-style: normal;
      font-size: 24px;
      line-height: 30px;

      span span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 12px;
        text-align: center;
        display: inline-block;
        background: #000;
        color: #fff;
      }
    }

    @media screen and (max-width: 740px) {
      max-width: 50%;
      position: relative;
      top: -85px;
      margin-top: -35px;
      text-align: left;
    }

    @media screen and (max-width: 340px) {
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      display: block;
      margin-top: 0;
    }
  }

  .closeButton {
    display: none;
    float: right;
    margin-left: 20px;

    @media screen and (max-width: 1200px) {
      display: inline-block;
      display: inline-block;
      position: relative;
      top: 0px;
    }

    @media screen and (max-width: 450px) {
      position: absolute !important;
      top: 28px !important;
      right: 20px;

      > span {
        display: none;
      }
    }
  }

  .noContentItem {
    margin: 30px 0;
    padding: 22px;
    background: rgba(0, 0, 0, 0.1);
    font-weight: 700;
    font-size: 18px;
  }
`

export const FilterWrapper = styled.div`
  background-color: #2e842a;
  color: ${white};
  padding: 4px 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 15px;

  > * {
    display: inline-block !important;
    vertical-align: middle;
    svg {
      margin-left: 10px;
    }
  }

  svg {
    cursor: pointer;
  }
`