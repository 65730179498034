import styled from 'styled-components'

const MapsWrapper = styled.div`
  width: 100%;
  height: 100%;

  .infoBox > img {
    display: none;
  }

  .cluster {
    > div {
      color: #fff !important;
      font-size: 18px !important;
      line-height: 37px !important;
      width: 40px !important;
    }
    > img {
      display: block !important;
      width: 40px;
      height: 40px;
    }
    > * {
      top: 20% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .infoBox {
    width: 290px;
    height: 250px !important;
    position: relative !important;
    overflow: initial !important;
  }

  .gm-style-moc {
    display: none !important;
  }

  .gm-ui-hover-effect {
    top: 0px !important;
    right: 12px !important;
    opacity: 1 !important;
    > span {
      width: 28px !important;
      height: 28px !important;
    }
  }
`

export default MapsWrapper
