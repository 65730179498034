import Maps from '../../../modules/maps'
import { Loader } from '@/app/loading'

// MapView
export const MapView = ({ data, isLocation = false, isLoading = false }) => {
    const defaultMapProps = {
      defaultCenter: {
        lat: 53.21103593782257,
        lng: 6.567086535707376,
      },
      defaultZoom: 13,
    }
    if (isLoading && Object.keys(data).length === 0) {
      // Show loader if data is loading without previous data
      return <Loader />
    }
  
    return (
      <div className="listMapWrapper">
        <Maps
          {...defaultMapProps}
          data={data.coordinates || data.items}
          widthOverwrites={{
            width: '100%',
            height: '860px',
          }}
          isLocation={isLocation}
        />
      </div>
    )
}
  