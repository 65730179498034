import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../elements/typography'
import { tag_grey, tag_border_grey } from '../../colors'

const CategoryWrapper = styled.div`
  display: inline-block;
  padding: 0 16px;
  background-color: ${tag_grey};
  border: 1px solid ${tag_border_grey};
  margin-right: 15px;

  @media screen and (max-width: 980px) {
    margin: 0 30px 10px 0;
  }
`

const Category = ({ children }) => {
  return (
    <CategoryWrapper>
      <TypographyElement variant="tagText">{children}</TypographyElement>
    </CategoryWrapper>
  )
}

export default Category
