import React from 'react'
import styled from 'styled-components'

import FAQCard from '../../compositions/faq'
import TypographyElement from '../../elements/typography'

const FAQWrapper = styled.div`
  margin-bottom: 110px;
  .faqs {
    margin-top: 23px;
    > * {
      margin-bottom: 12px;
    }
  }
`

const FAQBlock = ({ title, questions }) => {
  return (
    <FAQWrapper>
      <TypographyElement variant="title" component="h2">
        {title}
      </TypographyElement>
      <div className="faqs">
        {questions?.map((question, index) => {
          return <FAQCard key={`faq-card-x-${index}`} {...question} />
        })}
      </div>
    </FAQWrapper>
  )
}

export default FAQBlock
