import React from 'react'
import styled from 'styled-components'

// Composition
import NewsCard from '../../compositions/newsCard'

// Modules
import TypographyElement from '../../elements/typography'
import ListsBlock from '../../modules/listsBlock'
import EventBlock from '../../modules/eventCalendar'
import FaqBlock from '../../modules/faq'
import { innerHtml } from '../../utils/innerHtml'

const Wrapper = styled.div`
  .dynamicStaticText {
    padding: 40px 0;
  }

  .newcardHolder {
    > div {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding: 0 0 0 12px;

      &:nth-child(1) {
        padding: 0 12px 0 0;
      }
    }
    > div > div {
      height: unset;
    }
    @media screen and (max-width: 600px) {
      > div {
        display: block;
        vertical-align: top;
        width: 100%;
      }
    }
  }
`

// news variant
const NewsBlock = ({ title, news }) => {
  return (
    <div className="newsContainer">
      <TypographyElement variant="title">{title}</TypographyElement>

      <div className="newcardHolder">
        <div>
          {news[0] && (
            <>
              <NewsCard {...news[0]} maxWidth showCategory={false} />
            </>
          )}
        </div>
        <div>
          {news[1] && (
            <>
              <NewsCard {...news[1]} maxWidth showCategory={false} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// Static content block
const StaticText = ({ title, text, body, children }) => {
  return (
    <>
      <div className="dynamicStaticText">
        {body ? (
          <>{innerHtml(body)}</>
        ) : (
          <>
            <TypographyElement variant="title" component="h2">
              {title}
            </TypographyElement>
            <TypographyElement variant="p">{innerHtml(text)}</TypographyElement>
          </>
        )}
      </div>

      {children && <div className="dynamicStaticChildren">{children}</div>}
    </>
  )
}

const staticMap = {
  list: ListsBlock,
  events: EventBlock,
  faq: FaqBlock,
  news: NewsBlock,
}

const DyanmicModule = ({ title, text, data, dynamicFilter, body }) => {
  let type = 'list'
  let dynamicData = {
    lists: data?.list?.lists || [],
  }

  if (data?.events && data.events.length > 0) {
    type = 'events'
    let dynamicTitle = dynamicFilter?.text
      ? dynamicFilter?.text[0].toUpperCase() + dynamicFilter?.text?.substring(1)
      : ''
    dynamicData = {
      hideButton: true,
      title: `${dynamicTitle} evenementen in Groningen`,
      events: data.events,
    }
  } else if (data?.news && data.news.length > 0) {
    type = 'news'
    dynamicData = {
      title: `Nieuws over ${dynamicFilter.text} in Groningen`,
      news: data.news,
    }
  } else if (data?.faq && data.faq.length > 0) {
    type = 'faq'
    dynamicData = {
      title: `Veelgestelde vragen over ${dynamicFilter?.text}`,
      questions: data.faq,
    }
  }

  const DynamicElement =
    type === 'list' && !data?.list ? () => <> </> : staticMap[type]

  return (
    <Wrapper>
      <StaticText title={title} text={text} body={body}>
        <DynamicElement {...dynamicData} hideButton />
      </StaticText>
    </Wrapper>
  )
}
export default DyanmicModule
