import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'

import EventDateCard from '../../compositions/eventDateCard'

const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 127px;
  .events {
    margin: 36px 0;
    > * {
      margin-bottom: 9px;
    }

    @media screen and (max-width: 700px) {
      margin-top: 0;

      span {
        display: block;
        padding: 10px 0;
      }
    }
  }
  .buttonContainer {
    margin: 20px 0;
    text-align: right;

    @media screen and (max-width: 340px) {
      text-align: center;
    }
  }
`

const EventCalendarBlock = ({ title, events, button, hideButton }) => {
  return (
    <Wrapper>
      <TypographyElement variant="title" component="h2">
        {title}
      </TypographyElement>
      {events && (
        <div className="events">
          {events?.map((event, index) => {
            return <EventDateCard key={`event-date-${index}`} {...event} />
          })}
        </div>
      )}
      {!hideButton && button && (
        <div className="buttonContainer">
          <ButtonElement variant="primaryLink" href={button?.href}>
            {button?.text}
          </ButtonElement>
        </div>
      )}
    </Wrapper>
  )
}

export default EventCalendarBlock
