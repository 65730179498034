import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'

const DateContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 180px;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

const DateSection = ({ startDay, startMonth, end }) => {
  return (
    <DateContainer>
      {end && (
        <TypographyElement variant="eventDateMonth">V.A. </TypographyElement>
      )}
      <TypographyElement variant="eventDateNumber">
        {startDay}
      </TypographyElement>{' '}
      <TypographyElement variant="eventDateMonth">
        {startMonth}
      </TypographyElement>
    </DateContainer>
  )
}

export default DateSection
