'use client'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { grey, white } from '../../colors'
import ImageBuilder from '../../elements/imageBuilder'
import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import Category from './categories'

import SkewContainer from '../../elements/skewWrapper'
import useWindowSize from '../../utils/useResize'

const SearchCardWrapper = styled.div`
  position: relative;

  .container {
    position: relative;
    height: 100%;
    padding: 22px 0;

    > a {
      display: block;
    }
  }
  .imageContainer {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 102px;
    margin-right: 30px;
    margin-left: 20px;
    span {
      width: 115px !important;
      height: 102px !important;
      margin: 0 auto;
    }
    > span {
      left: 50%;
      transform: translateX(-50%);
      -ms-transform: translateX(-50%);
    }

    span {
      height: inherit !important;
      img {
        object-fit: contain;
        object-position: center;
      }
    }

    width: 150px;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      img {
        left: -12%;
      }
    }
  }
  .textContainer {
    max-width: calc(100% - 200px);
    display: inline-block;
    vertical-align: middle;
    .categories {
      padding-top: 28px;
      display: inline-block;
      height: 55px;
      overflow: hidden;
    }
  }
  .buttonContainer {
    position: absolute;
    right: -26px;
    bottom: -20px;

    @media screen and (max-width: 980px) {
      bottom: 0;
    }
  }

  @media screen and (max-width: 980px) {
    .imageContainer,
    .textContainer {
      display: block;
      width: 100%;

      .categories {
        padding-top: 8px;
      }
    }

    .textContainer {
      max-width: calc(100% - 110px);
    }
  }

  @media screen and (max-width: 760px) {
    .textContainer {
      max-width: 100%;

      .categories {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .textContainer {
      height: 110px;
      h3 {
        text-align: center;
      }
      .categories {
        display: none;
      }
    }

    h3 {
      font-size: 24px;
      position: relative;
      top: 10px;
    }

    .imageContainer {
      margin: 0;
    }

    .buttonContainer {
      transform: scale(0.8);
    }
  }
`

const NonSkewedContainer = styled.div`
  display: block;
  background-color: white !important;
  width: 100%;
  border: 1px solid #8c8c8c;
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  margin-right: 1px;
  .searchCardWrapper {
    margin-bottom: 0;
  }
  .container {
    height: auto !important;
    .textContainer {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      padding: 20px;
    }
  }
  .buttonContainer {
    position: absolute;
    top: unset;
    bottom: 0px;
    left: unset;
    right: 0px;
    .arrowContainer {
      width: 50px;
      height: 50px;
      background-color: #3baa34 !important;
      .svgContainer {
        top: 15px !important;
        right: unset;
        left: 15px !important;

        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }
`

const SearchCard = ({
  title,
  categories,
  img,
  logo,
  isSmall = false,
  sports = null,
  slug,
}) => {
  const { pageWidth } = useWindowSize()
  const skew = pageWidth < 1000 ? '13' : '18.88'

  const displayImage = logo || img

  const link = `/sportaanbieders/${slug}`
  const shorten = categories.length > 3
  const useCategories = shorten ? categories.slice(0, 3) : categories

  const isSmallScreen = pageWidth <= 750

  return (
    <Link href={link} title={title}>
      {isSmallScreen ? (
        <NonSkewedContainer>
          <SearchCardWrapper className="searchCardWrapper">
            <div className="container">
              <div className="imageContainer">
                {displayImage?.url && (
                  <ImageBuilder
                    image={displayImage}
                    alt={displayImage.title}
                    width={115}
                    height={102}
                    decorativeOnly={true}
                  />
                )}
              </div>
              <div className="textContainer">
                <TypographyElement variant="searchCardTitle">
                  {title}
                </TypographyElement>
                <div className="categories">
                  {useCategories?.map((item, i) => {
                    return (
                      <Category id={item?.title} key={`category-${i}`}>
                        {item?.title}
                      </Category>
                    )
                  })}
                  {shorten && (
                    <Category id="extra_categories" key="extra_categories">
                      +{categories.length - 3}...
                    </Category>
                  )}
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <div className="arrowContainer">
                <ButtonElement variant="arrow" noLink />
              </div>
            </div>
          </SearchCardWrapper>
        </NonSkewedContainer>
      ) : (
        <SkewContainer
          displayBlock
          skew={`-${skew}deg`}
          style={`
                background-color: ${white};
                display: block;
                height: 152px;
                width: 100%;
                border: 1px solid ${grey};

                @media screen and (max-width: 980px) {
                    height: 232px;
                }

                box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
            `}
          containerStyling={`
                padding: 0;
                width: 100%;
                ${isSmall && `max-width: 850px;`}
                height: 152px;
                padding: 0px 50px;

                @media screen and (max-width: 1200px) {
                    padding: 0;
                }

                div.skewWrapper {
                    padding: 0;
                    width: 100%;
                    height: 152px;
                }

                @media screen and (max-width: 980px) {
                    height: 232px;

                    div.skewWrapper {
                        height: 232px;
                    }
                }
            `}
        >
          <SearchCardWrapper>
            <div className="container">
              <div className="imageContainer">
                {displayImage?.url && (
                  <ImageBuilder
                    image={displayImage}
                    alt={displayImage.title}
                    width={115}
                    height={102}
                  />
                )}
              </div>
              <div className="textContainer">
                <TypographyElement variant="searchCardTitle">
                  {title}
                </TypographyElement>
                <div className="categories">
                  {useCategories?.map((item, i) => {
                    return (
                      <Category id={item?.title} key={`category-${i}`}>
                        {item?.title}
                      </Category>
                    )
                  })}
                  {shorten && (
                    <Category id="extra_categories" key="extra_categories">
                      +{categories.length - 3}...
                    </Category>
                  )}
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="buttonContainer">
              <SkewContainer
                containerStyling={`
                            height: 76px;
                            width: 79px;
                            max-height: 76px;
                            padding: 0;

                            div.skewWrapper {
                                padding: 0 !important;
                                height: 76px;
                                max-height: 76px;
                                width: 79px;
                            }

                            .svgContainer {
                                position: relative;
                            }

                            a {
                                margin: 0 !important;
                                background: none!important;
                            }
                        `}
                style={`
                            background-color: #3Baa34;
                        `}
                skew={`-${skew}deg`}
              >
                <ButtonElement variant="arrow" noLink />
              </SkewContainer>
            </div>
          </SearchCardWrapper>
        </SkewContainer>
      )}
    </Link>
  )
}

export default SearchCard
