'use client'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { grey, white } from '../../colors'
import TypographyElement from '../../elements/typography'
import ButtonElement, { InnerSkewedLink } from '../../elements/buttons'
import Category from '../searchCard/categories'

import SkewContainer, { SkewImage } from '../../elements/skewWrapper'
import ImageBuilder from '../../elements/imageBuilder'
import useWindowSize from '../../utils/useResize'

const LocationCardWrapper = styled.div`
  position: relative;

  .container {
    position: relative;
    height: 100%;
    padding: 8px 8px 5px;

    > a {
      display: block;
    }
  }
  .imageContainer {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 136px;
    margin-right: 65px;
    margin-left: -28px;
    height: 136px;
    width: 181px;
    > div {
      height: 100%;

      img {
        left: -12%;
      }
    }
    span {
      /* width: 181px !important; */
      height: 136px !important;
      margin: 0 auto;
    }
    > div > span {
      margin-left: -13% !important;
      width: 228px !important;
      max-width: unset !important;
    }
  }
  .textContainer {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 250px);
    .categories {
      padding-top: 28px;
      display: inline-block;
      height: 55px;
      overflow: hidden;
    }
  }
  .buttonContainer {
    position: absolute;
    right: -26px;
    bottom: -20px;

    @media screen and (max-width: 980px) {
      bottom: 0;
    }
  }

  @media screen and (max-width: 750px) {
    .imageContainer {
      margin: 0 auto;
    }
    .textContainer {
      display: block;
      width: 100%;
      margin: 0;
      padding-top: 8px;
      left: 0;
      max-width: calc(100% - 110px);

      .categories {
        padding-top: 8px;
      }
    }

    .buttonContainer {
      transform: scale(0.8);
    }
  }

  @media screen and (max-width: 750px) {
    .textContainer {
      max-width: 100%;

      .categories {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        height: 55px;

        div {
          display: none;
        }
      }

      h3 {
        font-size: 26px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .buttonContainer {
      display: none;
    }

    .imageContainer {
      height: 136px;
      width: 100%;
      span {
        /* width: 181px !important; */
        height: 136px !important;
        margin: 0 auto;
      }
      > div > span {
        margin-left: 0% !important;
        width: 100% !important;
        max-width: unset !important;

        img {
          object-fit: cover !important;
        }
      }
    }
  }
`

const NonSkewedContainer = styled.div`
  display: block;
  background-color: white !important;
  width: 100%;
  border: 1px solid #8c8c8c;
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  margin-right: 1px;
  .searchCardWrapper {
    margin-bottom: 0;
  }
  .container {
    height: auto !important;
    .textContainer {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      padding: 20px;
    }
  }
  .buttonContainer {
    position: absolute;
    top: unset;
    bottom: 0px;
    left: unset;
    right: 0px;
    .arrowContainer {
      width: 50px;
      height: 50px;
      background-color: #3baa34 !important;
      .svgContainer {
        top: 15px !important;
        right: unset;
        left: 15px !important;

        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }
`

const LocationCard = ({
  title,
  categories,
  url,
  href,
  img,
  search_img,
  isSmall = false,
}) => {
  const { pageWidth } = useWindowSize()
  const skew = pageWidth < 750 ? '0' : pageWidth < 1000 ? '13' : '18.88'

  const link = href || url
  const shorten = categories.length > 3
  const useCategories = shorten ? categories.slice(0, 3) : categories
  const image = search_img || img

  return (
    <InnerSkewedLink href={link} title={title}>
      <div>
        <div className="skewContainerContainer">
          <SkewContainer
            displayBlock
            skew={`-${skew}deg`}
            style={`
                                background-color: ${white};
                                display: block;
                                height: 152px;
                                width: 100%;
                                border: 1px solid ${grey};

                                @media screen and (max-width: 750px) {
                                    height: 252px;
                                }

                                box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
                            `}
            containerStyling={`
                            padding: 0;
                            width: 100%;
                            ${isSmall && `max-width: 850px;`}
                            height: 152px;
                            padding: 0px 50px;

                            div.skewWrapper {
                                padding: 0;
                                width: 100%;
                                height: 152px;
                            }

                            @media screen and (max-width: 750px) {
                                height: 232px;

                                div.skewWrapper {
                                    height: 232px;
                                }
                            }

                            @media screen and (max-width: 750px) {
                                margin: 0;
                                padding: 0;

                                div.skewWrapper {
                                    height: 232px;
                                    padding: 0 !important;
                                }
                            }
                        `}
          >
            <LocationCardWrapper>
              <div className="container">
                <div className="imageContainer">
                  <SkewImage
                    skew={`${skew}deg`}
                    containerStyling={`
                                
                                    `}
                    height={0}
                    noTop
                  >
                    <ImageBuilder
                      image={image}
                      alt={img.title}
                      width={227}
                      height={136}
                      maxWidth='125%'
                    />
                  </SkewImage>
                </div>
                <div className="textContainer">
                  <TypographyElement variant="searchCardTitle">
                    {title}
                  </TypographyElement>
                  <div className="categories">
                    {useCategories?.map((item, i) => {
                      return (
                        <Category id={item?.title} key={`category-${i}`}>
                          {item?.title}
                        </Category>
                      )
                    })}
                    {shorten && (
                      <Category id="extra_categories" key="extra_categories">
                        +{categories.length - 3}...
                      </Category>
                    )}
                  </div>
                </div>
              </div>
              <div className="buttonContainer">
                <SkewContainer
                  containerStyling={`
                                        height: 76px;
                                        width: 79px;
                                        max-height: 76px;
                                        padding: 0;

                                        div.skewWrapper {
                                            padding: 0 !important;
                                            height: 76px;
                                            max-height: 76px;
                                            width: 79px;
                                        }

                                        .svgContainer {
                                            position: relative;
                                            // right: 27px;
                                        }

                                        a {
                                            margin: 0 !important;
                                            background: none!important;
                                        }
                                    `}
                  style={`
                                        background-color: #3Baa34;
                                    `}
                  skew={`-${skew}deg`}
                >
                  <ButtonElement variant="arrow" noLink />
                </SkewContainer>
              </div>
            </LocationCardWrapper>
          </SkewContainer>
        </div>
      </div>
    </InnerSkewedLink>
  )
}

export default LocationCard
