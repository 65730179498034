import SearchCardCTA from '@/core/compositions/searchCta'
import SearchCardCTALight from '@/core/compositions/searchCtaLight'
import TypographyElement from '@/core/elements/typography'
import { innerHtml } from '@/core/utils/innerHtml'

export const orderOptions = [
    {
        value: 'title',
        name: 'Alfabetisch (A - Z)',
    },
    {
        value: '-title',
        name: 'Alfabetisch (Z - A)',
    },
]

export const callToActionMap = {
    light: SearchCardCTALight,
    colored: SearchCardCTA,
}

export const getDynamicIntro = (dynamicContent, title, text) => {
    if (dynamicContent?.intro) {
        // Return the intro
        return innerHtml(dynamicContent?.intro)
    } else if (dynamicContent?.title || dynamicContent?.text) {
        // Return title, text from dynamicContent
        const { title, text } = dynamicContent
        return (
            <>
                {title && title.length > 0 && (
                    <TypographyElement variant="title">{title}</TypographyElement>
                )}
                {innerHtml(text)}
            </>
        )
    }
    // Default to title & text
    return (
        <>
            {title && title.length > 0 && (
                <TypographyElement variant="title">{title}</TypographyElement>
            )}
            {innerHtml(text)}
        </>
    )
}
