// HEAVILY WIP
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Marker,
  MarkerF,
  MarkerClustererF,
  InfoBox,
  InfoWindowF,
} from '@react-google-maps/api'
import MapsWrapper from './maps.styled'
import TypographyElement from '../../elements/typography'
import Category from '../../compositions/searchCard/categories'
import ButtonElement from '../../elements/buttons'
import { quarternary_black } from '../../colors'

// BoxWrapper
const BoxWrapper = styled.div`
  position: relative;
  background: #fff;
  width: 280px;
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;

  .boxTitleArea {
    height: 50px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    > div {
      position: absolute;
      right: 22px;
      top: 12px;
      font-weight: bold;
      font-size: 22px;
      cursor: pointer;
    }
  }
`

// CardWrapper
const CardWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .categories {
    padding-top: 28px;

    > div {
      margin-bottom: 12px;
    }
  }
  .linkWrapper {
    width: 100%;
    .singlecardLink {
      flex: 1;
    }
  }
`

// Loading indicator
const Spinner = () => {
  return <div> Loading Map </div>
}

// Map settings
const mapContainerStyle = {
  height: '100%',
  width: '100vw',
}

function createKey(location, i) {
  return location.lat + location.lng + i
}

// Options
const options = {
  imagePath: '/maps/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

// Single Card
const SingleCard = ({
  title,
  categories,
  sports,
  slug,
  url,
  isLocation = false,
}) => {
  const link = isLocation ? url : `/sportaanbieders/${slug}`
  return (
    <ButtonElement variant="base" href={link}>
      <CardWrapper>
        <ButtonElement variant="base" href={link} className="singlecardLink">
          <TypographyElement
            variant="openingtimeTimeslot"
            color={quarternary_black}
            sx={{
              width: 'calc(100% - 30px)',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            {title}
          </TypographyElement>
          <ArrowForwardIcon
            height={30}
            width={20}
            sx={{
              width: '20px',
              display: 'inline-block',
              verticalAlign: 'top',
              height: '30px',
            }}
          />
        </ButtonElement>
        <div className="categories">
          {categories?.map((item, index) => {
            if (index === 3) {
              return (
                <Category key={`${item.title}-cat-${index}`} id={item.title}>
                  +{categories.length - 4}..
                </Category>
              )
            }
            if (index >= 3) {
              return null
            }
            return (
              <Category key={`${item.title}-cat-${index}`} id={item.title}>
                {item.title}
              </Category>
            )
          })}
        </div>
      </CardWrapper>
    </ButtonElement>
  )
}

// Module
function GoogleMapModule({
  defaultCenter,
  defaultZoom,
  data,
  widthOverwrites,
  isLocation = false,
  markersOnly,
}) {
  const [selected, setSelected] = React.useState([])
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDRzjCwZbOUwluc8pAdAQQV5AlwQv4rliU', // ,
  })

  const extractedCoords = React.useMemo(() => {
    // Get all individual locations
    let validData = []
    data?.forEach((location) => {
      location?.cords?.forEach((cord) => {
        if (
          cord?.lat &&
          cord?.lng &&
          typeof cord?.lng == 'number' &&
          typeof cord?.lat == 'number'
        ) {
          validData.push({
            ...cord,
            ...location,
          })
        }
      })
    })
    return validData || []
  }, [data])

  const clustererRef = useRef()
  useEffect(() => {
    clustererRef.current?.repaint()
  }, [extractedCoords.length])

  const handleClusterclick = (a) => {
    setSelected([])
    const markers = a.getMarkers()
    const locations = []
    markers?.forEach((marker) => {
      const lat = marker.position.lat()
      const lng = marker.position.lng()
      const markerLocations = extractedCoords.filter(
        (item) => item.lat === lat && item.lng === lng
      )
      // const specificItem = specificItems[0]
      markerLocations.forEach((markerLocation) => {
        if (!locations.some((location => markerLocation.id === location.id))) {
          locations.push(markerLocation) 
        }
      })
      // return specificItem
    })
    setSelected(locations || [])
  }

  const handleMarkerclick = (a) => {
    setSelected([a])
  }

  const renderMap = () => {
    return (
      <MapsWrapper>
        <GoogleMap
          mapContainerStyle={widthOverwrites || mapContainerStyle}
          defaultZoom={defaultZoom}
          zoom={defaultZoom}
          center={defaultCenter}
          onZoomChanged={() => setSelected([])}
          onDrag={() => setSelected([])}
        >
          {!markersOnly && selected && selected?.length > 0 && (
            <InfoWindowF
              position={{
                lat: selected[0].lat,
                lng: selected[0].lng,
              }}
            >
              <BoxWrapper>
                {selected?.map((item, index) => {
                  return (
                    <SingleCard
                      key={`map-card-item-${index}`}
                      isLocation={isLocation}
                      {...item}
                    />
                  )
                })}
              </BoxWrapper>
            </InfoWindowF>
          )}
          {isLoaded && extractedCoords?.length && (
            <MarkerClustererF
              onClick={handleClusterclick}
              zoomOnClick={false}
              minimumClusterSize={1}
              options={options}
              onLoad={(clusterer) => (clustererRef.current = clusterer)}
            >
              {(clusterer) =>
                extractedCoords?.map((location, i) => {
                  return (
                    <MarkerF
                      key={createKey(location, i)}
                      position={location}
                      clusterer={clusterer}
                      onClick={() => handleMarkerclick(location)}
                    />
                  )
                })
              }
            </MarkerClustererF>
          )}
        </GoogleMap>
      </MapsWrapper>
    )
  }

  if (loadError) {
    return <div>Map Error</div>
  }

  return isLoaded ? renderMap() : <Spinner />
}

const getCoordinateString = (marker) => {
  return `${marker.lat},${marker.lng}`
}

// Module
export function GoogleMapSelectModule({
  defaultCenter = {
    lat: 53.21103593782257,
    lng: 6.567086535707376,
  },
  defaultZoom = 13,
  widthOverwrites = {
    width: '100%',
    height: '450px',
  },
  markers,
  addMarker,
  removeMarker,
}) {
  const [position, setPosition] = React.useState(defaultCenter)
  const mapRef = useRef(null)

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDRzjCwZbOUwluc8pAdAQQV5AlwQv4rliU',
  })

  const handleMarkerclick = (markerId) => {
    // Remove marker from map
    removeMarker(markerId)
  }

  const handleMapClick = (e) => {
    // Add new marker
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const id = `marker-coordinates-${lat}-${lng}`
    const newMarker = {
      lat,
      lng,
      id,
    }
    addMarker(newMarker)
  }

  function handleLoad(map) {
    mapRef.current = map
  }

  function handleCenter() {
    if (!mapRef.current) return
    const newPos = mapRef.current.getCenter().toJSON()
    setPosition(newPos)
  }

  const renderMap = () => {
    return (
      <MapsWrapper>
        <GoogleMap
          onLoad={handleLoad}
          onDragEnd={handleCenter}
          center={position}
          mapContainerStyle={widthOverwrites || mapContainerStyle}
          defaultZoom={defaultZoom}
          zoom={defaultZoom}
          onClick={handleMapClick}
        >
          {markers?.map((marker, i) => (
            <Marker
              key={`${marker.id}-${i}`}
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
              onClick={() => handleMarkerclick(marker.id)}
            />
          ))}
        </GoogleMap>
        {markers?.map((marker, id) => {
          return (
            <input
              type="hidden"
              name={`coordinate-${id}`}
              key={`coordinate-${id}`}
              value={getCoordinateString(marker)}
            />
          )
        })}
      </MapsWrapper>
    )
  }

  if (loadError) {
    return <div>Map Error</div>
  }

  return isLoaded ? renderMap() : <Spinner />
}

export default GoogleMapModule
