import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../elements/typography'

const InfoWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 14px;
  width: calc(100% - 204px);
  padding-right: 60px;

  .innerInfo {
    display: flex;
    flex-direction: column;
    .date {
      order: 0;
    }
    .title {
      order: 1;
    }
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

const InfoSection = ({ fullDate, title }) => {
  return (
    <InfoWrapper className="info">
      <div className="innerInfo">
        <TypographyElement
          variant="eventBlockTitle"
          component="h3"
          className="title"
        >
          {title}
        </TypographyElement>
        <TypographyElement
          variant="eventBlockSubtitle"
          component="span"
          className="date"
        >
          {fullDate}
        </TypographyElement>
      </div>
    </InfoWrapper>
  )
}

export default InfoSection
