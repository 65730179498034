'use client'
import React from 'react'
import styled from 'styled-components'
import ListItemElement from '../elements/listitem'
import ButtonElement from '../elements/buttons'
import TypographyElement from '../elements/typography'

const ListContainer = styled.div`
  display: inline-block;
  .listitems {
    padding-top: 20px;
    padding-bottom: 40px;
  }
`

const List = ({ title, items, links }) => {
  const [showall, setShowall] = React.useState(false)
  items = items || links || []
  const filteredItems = showall ? items : items.slice(0, 4)
  return (
    <ListContainer>
      <TypographyElement variant="listTitle">{title}</TypographyElement>
      <div className="listitems">
        {filteredItems?.map((item, index) => {
          return <ListItemElement key={`${item.text}=${index}`} {...item} />
        })}
        <ButtonElement
          variant="more"
          handleClick={() => {
            setShowall(!showall)
          }}
        >
          {showall ? 'Minder tonen' : 'Alles tonen'}
        </ButtonElement>
      </div>
    </ListContainer>
  )
}

export default List
