'use client'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import ImageBuilder from '../../elements/imageBuilder'
import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import ColorBar from '../../elements/colorbars'

import SkewContainer, { SkewImage } from '../../elements/skewWrapper'
import useWindowSize from '../../utils/useResize'

const SearchCardWrapper = styled.div`
  position: relative;

  .container {
    position: relative;
    height: 238px;
    padding: 22px 0;

    h3 {
      white-space: normal !important;
    }

    > a {
      display: block;
    }
  }
  .imageContainer {
    display: inline-block;
    vertical-align: middle;
    width: auto;

    img {
      object-fit: cover;
    }

    @media screen and (max-width: 1500px) {
      width: 200px;

      img {
        height: 200px;
      }
    }

    position: relative;
    left: -50px;
    bottom: -2px;

    .backgroundAsset {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: none;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }

    width: 300px;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      img {
        left: 0;
      }
    }
  }
  .textContainer {
    position: absolute;
    top: 30%;
    left: 380px;
    width: calc(100% - 380px);
    max-width: 500px;

    @media screen and (max-width: 1500px) {
      width: calc(100% - 220px);
      max-width: 800px;
      left: 220px;
    }

    @media screen and (max-width: 800px) {
      width: calc(100% - 20px);
      max-width: 800px;
      left: 20px;
      max-height: 133px;
    }

    h3 {
      color: #fff;
      font-size: 26px;
    }
  }
  .buttonContainer {
    position: absolute;
    right: -52px;
    top: 6px;

    @media screen and (max-width: 1000px) {
      top: 0;
      right: -48px;
    }
  }
`

const ColorBarHolder = styled.div`
  position: absolute;
  bottom: -10px;
  left: -38px;
  width: 100%;
  height: 30px;
  @media screen and (max-width: 1000px) {
    left: -25px;
  }
`

const NonSkewedContainer = styled.div`
    display: block;
    background-color: #2E842A; !important;
    width: 100%;
    box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
    margin-right: 1px;
    .searchCardWrapper {
        margin-bottom: 0;
    }
    .container {
        height: auto !important;
        padding: 42px 0;
        .textContainer {
            display: block;
            position: relative;
            top: unset;
            left: unset;
            width: 100%;
            padding: 30px;
            max-height: unset !important;
        }
    }
    .buttonContainer {
        position: absolute;
        top: 20px;
        bottom: unset;
        left: unset;
        right: 40px;
        .svgContainer {
            top: unset !important;
            right: unset;
            left: unset !important;

            svg {
                path {
                    stroke: white;
                }
            }
        }
    }
    /* @media screen and (max-width: 500px) {
        display: block;
    } */
`

const SkewedWrapper = styled.div`
  display: block;
  /* @media screen and (max-width: 500px) {
        display: none;
    } */
`

const NonSkewColorBarHolder = styled.div`
  width: 100%;
`

const SearchCard = ({ title, href, img, search_img }) => {
  const { pageWidth } = useWindowSize()
  const skew = pageWidth < 1000 ? '13' : '18.88'
  const image = search_img || img

  const isSmall = pageWidth <= 750
  const isLarge = pageWidth >= 1500

  return (
    <Link href={href} title={title}>
      {isSmall ? (
        <NonSkewedContainer>
          <SearchCardWrapper className="searchCardWrapper">
            <div className="container">
              <div className="imageContainer"></div>
              <div className="textContainer">
                <TypographyElement variant="searchCardTitle">
                  {title}
                </TypographyElement>
              </div>
            </div>
            {href && (
              <div className="buttonContainer">
                <ButtonElement variant="arrow" noLink={true} />
              </div>
            )}
          </SearchCardWrapper>
          <NonSkewColorBarHolder>
            <div>
              <ColorBar
                customStyling={`width: 100%; position: relative !important`}
              />
            </div>
          </NonSkewColorBarHolder>
        </NonSkewedContainer>
      ) : (
        <SkewedWrapper>
          <SkewContainer
            displayBlock
            skew={`-${skew}deg`}
            style={`
                        background: #2E842A;
                        display: block;
                        height: 238px;
                        width: 100%;

                        box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
                    `}
            containerStyling={`
                        padding: 0;
                        width: 100%;
                        height: 238px;
                        padding: 0px 50px;
                        overflow: hidden;

                        @media screen and (max-width: 1200px) {
                            padding: 0px 50px;
                            margin: 0px -20px;
                            width: 104%;
                        }

                        @media screen and (max-width: 900px) {
                            padding: 0px 30px;
                        }

                        div.skewWrapper {
                            padding: 0;
                            width: 100%;
                            height: 238px;
                        }
                    `}
          >
            <SearchCardWrapper>
              <div className="container">
                <div className="imageContainer">
                  <SkewImage
                    skew={`${skew}deg`}
                    height={0}
                    isCTA={true}
                    containerStyling={`
                                        margin-left: 40px;
                                        > span {
                                            margin-left: -33px !important;
                                        }
                                    `}
                  >
                    {image && (
                      <span>
                        <ImageBuilder
                          image={image}
                          alt={image.title}
                          width={isLarge ? 375 : 270}
                          height={199}
                        />
                      </span>
                    )}
                  </SkewImage>
                </div>
                <div className="textContainer">
                  <TypographyElement variant="searchCardTitle">
                    {title}
                  </TypographyElement>
                </div>
              </div>
              <div className="buttonContainer">
                <SkewContainer
                  containerStyling={`
                                height: 76px;
                                width: 90px;
                                max-height: 76px;
                                padding: 0;

                                div.skewWrapper {
                                    padding: 0 !important;
                                    height: 76px;
                                    max-height: 76px;
                                    width: 90px;
                                }

                                .svgContainer {
                                    position: relative;
                                    right: -20px;
                                    top: 30px;
                                }

                                a {
                                    margin: 0 !important;
                                    background: none!important;
                                }
                            `}
                  style={`
                                background: #2E842A;
                            `}
                  skew={`-${skew}deg`}
                >
                  <ButtonElement variant="arrow" href={href} noLink={true}  />
                </SkewContainer>
              </div>
            </SearchCardWrapper>

            <ColorBarHolder>
              <SkewImage skew={`${skew}deg`} height={30}>
                <span>
                  <ColorBar
                    customStyling={`width: 120%; margin-left: -20px;`}
                  />
                </span>
              </SkewImage>
            </ColorBarHolder>
          </SkewContainer>
        </SkewedWrapper>
      )}
    </Link>
  )
}

export default SearchCard
