import React from 'react'
import styled from 'styled-components'
import ButtonElement from '../../elements/buttons'

import DateSection from './date'
import InfoSection from './info'
import { getMomentTz } from '../../utils/getMomentTz'

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
`

const LinkWrapperEvent = styled.div`
  @media screen and (max-width: 700px) {
    a {
      padding: 40px;
    }

    svg {
      display: none;
    }
  }
  @media screen and (max-width: 340px) {
    a {
      padding: 20px;
    }
  }
`

const EventDateCard = ({ event_start, event_end, title, url }) => {
  const startDate = getMomentTz(event_start)
  const startDay = startDate.format('dddd')
  const startDayNr = startDate.format('D')
  const startMonth = startDate.format('MMMM')
  const startMonthShort = startDate.format('MMM')
  const startTime = getMomentTz(event_start).format('HH:mm')

  let endDate = event_end ? getMomentTz(event_end) : null
  const singleDay = endDate?.isAfter(startDate, 'day')
  let fullDate
  if (endDate && singleDay) {
    const endDay = endDate.format('dddd')
    const endDayNr = endDate.format('D')
    const endMonth = endDate.format('MMMM')
    fullDate = `${startDay} ${startDayNr} ${startMonth} t/m ${endDay} ${endDayNr} ${endMonth}`
  } else {
    fullDate = `${startDay} ${startDayNr} ${startMonth} om ${startTime}u`
  }

  return (
    <LinkWrapperEvent>
      <ButtonElement variant="border" href={url}>
        <Wrapper>
          <DateSection
            startDay={startDayNr}
            startMonth={startMonthShort}
            end={singleDay && event_end}
          />
          <InfoSection fullDate={fullDate} title={title} />
        </Wrapper>
      </ButtonElement>
    </LinkWrapperEvent>
  )
}

export default EventDateCard
